import { format, toZonedTime } from 'date-fns-tz';
import { useUserStore } from '../stores/user';

const userStore = useUserStore();

const getDefaultTimezone = () => {
  const timezone = userStore.user?.local_timezone ?? 'America/New_York';
  // Ensure that the returned timezone string is correctly formatted
  return timezone.replace(' ', '_');
};

const getTimezoneAbbreviation = (date: Date, timeZone: string = getDefaultTimezone()): string => {
  // Ensure timeZone is a valid IANA time zone identifier
  timeZone = timeZone.replace(' ', '_');

  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZoneName: 'short',
    timeZone,
  });
  const formatted = formatter.format(date);
  const match = formatted.match(/\b([A-Z]+)\b/);
  return match ? match[0] : '';
};

// Define the types for the dateFromTz function
export const dateFromTz = (dateString: string, zoneName: string = getDefaultTimezone()): Date => {
  return toZonedTime(dateString, zoneName);
};

// Define the types for the shortDateFromTz function
export const shortDateFromTz = (dateString: string, zoneName: string = getDefaultTimezone()): string | null => {
  if (dateString == null) {
    return null;
  }
  const date = dateFromTz(dateString, zoneName);
  const monthAbbrev = format(date, 'MMM', { timeZone: zoneName });
  const day = format(date, 'd', { timeZone: zoneName });
  const year = format(date, 'yyyy', { timeZone: zoneName });

  return `${monthAbbrev} ${day} ${year}`;
};

// Define the types for the shortDateWithTimeTz function
export const shortDateWithTimeTz = (dateString: string, zoneName: string = getDefaultTimezone()): string | null => {
  if (dateString == null) {
    return null;
  }

  const date = dateFromTz(dateString, zoneName);

  const monthAbbrev = format(date, 'MMM', { timeZone: zoneName });
  const day = format(date, 'd', { timeZone: zoneName });
  const hour = format(date, 'h', { timeZone: zoneName });
  const amPm = format(date, 'aaa', { timeZone: zoneName });
  const year = format(date, 'yyyy', { timeZone: zoneName });

  const zone = getTimezoneAbbreviation(date, zoneName.replace('_', ' '));

  return `${monthAbbrev} ${day}, ${year} at ${hour}${amPm} ${zone}`;
};

// Define the types for the shortDateWithHourMinTz function
export const shortDateWithHourMinTz = (dateString: string, zoneName: string = getDefaultTimezone()): string | null => {
  if (dateString == null) {
    return null;
  }

  const date = dateFromTz(dateString, zoneName);

  const monthAbbrev = format(date, 'MMM', { timeZone: zoneName });
  const day = format(date, 'd', { timeZone: zoneName });
  const hour = format(date, 'h', { timeZone: zoneName });
  const minute = format(date, 'mm', { timeZone: zoneName });
  const amPm = format(date, 'aaa', { timeZone: zoneName });
  const zone = getTimezoneAbbreviation(date, zoneName.replace('_', ' '));

  return `${monthAbbrev} ${day} at ${hour}:${minute}${amPm} ${zone}`;
};

export const montDayYearShortTime = (dateString: string, zoneName: string = getDefaultTimezone()): string | null => {
  if (dateString == null) {
    return null;
  }

  const date = dateFromTz(dateString, zoneName);

  const month = format(date, 'M', { timeZone: zoneName });
  const day = format(date, 'd', { timeZone: zoneName });
  const hour = format(date, 'h', { timeZone: zoneName });
  const amPm = format(date, 'aaa', { timeZone: zoneName });
  const year = format(date, 'yyyy', { timeZone: zoneName });

  const zone = getTimezoneAbbreviation(date, zoneName.replace('_', ' '));

  return `${year}/${month}/${day} at ${hour}${amPm} ${zone}`;
};

export const yearMonthDayFormat = (dateString: string, dateStyle: string = '/', zoneName: string = getDefaultTimezone()): string | null => {
  if (dateString == null) {
    return null;
  }

  const date = new Date(dateString);

  const month = format(date, 'MM');
  const day = format(date, 'dd');
  const year = format(date, 'yyyy');

  if (dateStyle == '-') {
    return `${year}-${month}-${day}`;
  } else {
    return `${year}/${month}/${day}`;
  }
};

export const dayMonthYearFormat = (dateString: string, dateStyle: string = '/'): string | null => {
  if (dateString == null) {
    return null;
  } 
  
  const date = new Date(dateString);

  if (dateStyle == '-') {
    return `${String(date.getDate()).padStart(2, "0")}-${String(date.getMonth() + 1).padStart(2, "0")}-${date.getFullYear()}`;
  } else {
    return `${String(date.getDate()).padStart(2, "0")}/${String(date.getMonth() + 1).padStart(2, "0")}/${date.getFullYear()}`;
  }
}